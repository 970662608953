import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoggerService } from './logger.service';
import { Page, PageItem } from '../models/page.model';
import { TenantService } from './tenant.service';

@Injectable()
export class ConfigService {

  customer!: string;

  constructor(
    private store: AngularFirestore,
    private logger: LoggerService,
    private tenant: TenantService
  ){
    this.customer = this.tenant.customer;
  }


  getConfig(path: string): Observable<Page | null> {
    return this.store.doc<Page>(`customers/${this.customer}/config/${path}`)
      .valueChanges()
      .pipe(map(data => data ? data : null));
  }

}


