<tui-root>
  <router-outlet></router-outlet>


  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs-->
  </ng-container>
  
  <ng-container ngProjectAs="tuiOverNotifications">
    <!-- Content over notifications -->
  </ng-container>
  
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>  
</tui-root>