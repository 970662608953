import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';

import { CoreModule } from '@maserati/core/core.module';

import { environment } from '@maserati/env/environment';
import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    RouterModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators.auth ? ['localhost', 9099] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators.firestore ? ['localhost', 8081] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators.functions ? ['localhost', 5001] : undefined },
  ]
})
export class AppModule {
}
