import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@maserati/env/environment';

@Injectable()
export class TenantService {

  customer!: string;


  constructor(
    private store: AngularFirestore
  ) {
    this.customer = this.parseCustomer(location.host);
  }


  parseCustomer(host: string): string {
    const result = environment.customer.regex.exec(host);

    if (result?.[1]) {
      return result[1];
    }

    return 'default';
 }

 getCustomerData(customer: string): Observable<any> {
    return this.store.doc(`customers/${customer}`)
      .valueChanges()
      .pipe(map(data => data ? data : null));
  }

}
