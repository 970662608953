import { Component, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'mst-core-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RootComponent {
}
