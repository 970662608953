import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Schema } from '@maserati/core/models/schema.model';
import { Tab } from '../models/tab.model';
import { LoggerService } from './logger.service';
import { UserLogLevel } from '../models/user.model';
import { TenantService } from './tenant.service';

@Injectable()
export class SchemaService {

  customer!: string;

  constructor(
    private store: AngularFirestore,
    private logger: LoggerService,
    private tenant: TenantService
  ){
    this.customer = this.tenant.customer;
  }


  getSchema(page: string | string[]): Observable<Schema | null> {
    const path = Array.isArray(page) ? page.join(`/schema/`) : page;

    return this.store.doc<Schema>(`customers/${this.customer}/schema/${path}`)
      .valueChanges()
      .pipe(map(data => data ? data : null));
  }

  tabsToSpec(index: number, tabs: Tab[]): string[] {
    const path = [] as string[];

    for(let i = 0; i <= index; i++) {
      if (i === 0 || 'field' in tabs[i]) {
        const item = tabs[i].field || tabs[i].id;
        path.push(item);
      }
    }

    return path;
  }

}


