import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';


@Injectable()
export class SavingService {

  saving$ = new BehaviorSubject<boolean>(false);

  constructor(){
    this.subscribeToBeforeunload();
  }

  public subscribeToBeforeunload() {
    fromEvent(window, 'beforeunload')
      .pipe(filter(() => this.saving$.getValue()))
      .subscribe(event => event.returnValue = true);
  }
}


