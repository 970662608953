import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { merge } from 'lodash';

import { DataItem, DataPath } from '../models/data.model';
import { Tab } from '../models/tab.model';
import { UserLogLevel } from '../models/user.model';
import { SavingService } from './saving.service';
import { LoggerService } from './logger.service';
import { TenantService } from './tenant.service';



@Injectable()
export class DataService {

  customer!: string;


  constructor(
    public saving: SavingService,
    private store: AngularFirestore,
    private logger: LoggerService,
    private tenant: TenantService
  ){
    this.customer = this.tenant.customer;
  }


  getInputData(path: DataPath): Observable<DataItem | null> {
    return this.store.doc<DataItem>(`customers/${this.customer}/data/${path.collection}/items/${path.document}`)
      .valueChanges()
      .pipe(map(data => data ? this.pathToData(data, path.path) : null));
  }

  async saveInputData(path: DataPath, data: Partial<DataItem>): Promise<boolean> {
    this.saving.saving$.next(true);

    try {
      const document = await this.getInputData({ collection: path.collection, document: path.document }).pipe(take(1)).toPromise();
      const mapped = this.saveToPath(data, path.path);

      const copy = merge(document, mapped, { updated: new Date() });

      await this.store.doc(`customers/${this.customer}/data/${path.collection}/items/${path.document}`).set(copy, { merge: true });

      this.saving.saving$.next(false);

      return true;
    } catch (err) {
      this.logger.log('{data.service, saveInputData()}', err, UserLogLevel.Error);
    }

    this.saving.saving$.next(false);

    return false;
  }

  async createInputData(path: DataPath): Promise<boolean> {
    try {
      await this.store.doc(`customers/${this.customer}/data/${path.collection}/items/${path.document}`).set({});

      return true;
    } catch (err) {
      this.logger.log('{data.service, createInputData()}', err, UserLogLevel.Error);
    }

    return false;
  }

  getItemsList(page: string, query?: QueryFn): Observable<DataItem[]> {
    return this.store.collection<DataItem>(`customers/${this.customer}/data/${page}/items`, query)
      .valueChanges({ idField: 'id' });
  }

  async deleteItem(page: string, id: string): Promise<boolean> {
    try {
      await this.store.doc(`customers/${this.customer}/data/${page}/items/${id}`).delete();

      return true;
    } catch (err) {
      this.logger.log('{data.service, deleteItem()}', err, UserLogLevel.Error);
    }

    return false;
  }

  getVersionsList(): Observable<DataItem[]> {
    return this.store.collection<DataItem>(`customers/${this.customer}/pages/status/list`)
      .valueChanges({ idField: 'id' });
  }

  getVersionsStatus(): Observable<DataItem | null> {
    return this.store.doc<DataItem>(`customers/${this.customer}/pages/status`)
      .valueChanges()
      .pipe(map(data => data ? data : null));
  }

  createDocumentId(): string {
    return this.store.createId();
  }

  tabsToDataInfo(index: number, tabs: Tab[]): DataPath {
    const result = {
      collection: tabs[0].id,
      document: tabs[1].id
    } as DataPath;

    if (tabs.length > 2 && index >= 2) {
      result.path = tabs.slice(2, 2 + index).map(tab => [ tab.field || '', tab.id ]);
    }

    return result;
  }

  saveToPath(data: any, path?: string[][]): DataItem {
    let original = {} as DataItem;

    if (path && path.length > 0) {
      let current = original;
      const flat = path.reduce((a, b) => a.concat(b), []);

      for (let i = 0, t = flat.length; i < t; i++) {
        const item = flat[i];

        current[item] = i === t - 1 ? data : {} as any;
        current = current[item] as any;
      }
    } else {
      original = data;
    }

    return original;
  }

  private pathToData(original: DataItem, path?: string[][]): DataItem {
    let current = original;

    if (path && path.length > 0) {
      const flat = path.reduce((a, b) => a.concat(b), []);

      for (const item of flat) {
        current[item] = (current[item] as any) || {};
        current = current[item] as any;
      }
    }

    return current as DataItem;
  }

}


