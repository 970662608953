// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  customer: {
    regex: /^([a-z0-9\-]+)\.cms\.(local\:4200|2na8\.dev)$/g
  },
  firebase: {
    apiKey: 'AIzaSyAtDuo3sPmu-D-aHq8AQWLKZtNDAUOQ7i4',
    authDomain: 'maserati-cms-dev.firebaseapp.com',
    projectId: 'maserati-cms-dev',
    storageBucket: 'maserati-cms-dev.appspot.com',
    messagingSenderId: '734295729262',
    appId: '1:734295729262:web:0d2837d0cf3f713e35727f'
  },
  useEmulators: {
    auth: false,
    functions: false,
    firestore: false
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
