import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@maserati/env/environment';

import { User, UserLog, UserLogLevel, UserLogType } from '../models/user.model';


@Injectable()
export class LoggerService {

  user!: User;


  constructor(
    private store: AngularFirestore,
  ) {
  }


  log(context: string, args: any, level: UserLogLevel = UserLogLevel.Debug) {
    if (environment.production) {
      if ('logger' in this.user) {
        this.registerLog(UserLogType.Log, level, context, args);
      }
    } else {
      const names = [ 'DEBUG', 'INFO', 'WARN', 'ERROR' ];
      console.log(names[level], context, args);
    }
  }

  async registerLog(type: UserLogType, level: UserLogLevel, context: string, args: any = null): Promise<boolean> {
    try {
      const data = {
        type,
        context,
        created: new Date(),
        data: args,
        userAgent: navigator.userAgent
      } as UserLog;

      if (this.user && this.user.logger <= level) {
        await this.store.collection(`users/${this.user.id}/log`).add(data);
        return true;
      }
    } catch(err) {
      console.log('ERR registerLog >>', err);
    }

    return false;
  }

}


