import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Image } from '@maserati/core/models/image.model';
import { DataPath } from '@maserati/core/models/data.model';
import { UserLogLevel } from '@maserati/core/models/user.model';
import { SavingService } from './saving.service';
import { LoggerService } from './logger.service';
import { TenantService } from './tenant.service';


@Injectable()
export class ImageService {

  downloadURL?: Observable<string>;
  customer!: string;


  constructor(
    private saving: SavingService,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions,
    private store: AngularFirestore,
    private logger: LoggerService,
    private tenant: TenantService
  ){
    this.customer = this.tenant.customer;
  }


  async doUpload(file: File, path: DataPath, inputName: string, crops: { name: string; type: string; width: number; height: number }[]): Promise<boolean> {
    this.saving.saving$.next(true);

    try {
      const random = Math.floor(Math.random() * 6553);
      const filename = `${random}_${file.name}`;

      const status = await this.storage.upload(`customers/${this.customer}/images/${filename}`, file);
      const metadata = status.metadata;

      const imageUrl = await this.getUrlImage(`customers/${this.customer}/images/${filename}`).pipe(take(1)).toPromise();

      const data = {
        name: metadata.name,
        type: metadata.contentType || null,
      } as Image;

      const image = await this.getImageByName(filename).pipe(take(1)).toPromise();

      if (!image) {
        const idImage = this.store.createId();
        data.id = idImage;
        data.url = imageUrl;
        data.created = new Date();

        await this.store.collection(`customers/${this.customer}/images`).doc(idImage).set(data);

      } else {
        data.id = image.id;
        data.updated = new Date();
        await this.store.collection(`customers/${this.customer}/images`).doc(image.id).update(data);
      }

      const cropStatus = await this.doCrop(data.id, path, inputName, crops);

      if (cropStatus) {
        this.saving.saving$.next(false);
        return true;
      }
    } catch (err) {
      this.logger.log('{image.service, doUpload()}', err, UserLogLevel.Error);
    }

    this.saving.saving$.next(false);
    return false;
  }

  async doCrop(id: string | undefined , path: DataPath,
      inputName: string, crops: { name: string; type: string; width: number; height: number }[]): Promise<boolean> {
    try {
      const customer = this.customer;
      const status = await this.functions.httpsCallable('generateThumbnail')({ customer, id, path, inputName, crops }).toPromise();

      return status;
    } catch (err) {
      this.logger.log('{image.service, doCrop()}', err, UserLogLevel.Error);
    }

    return false;
  }

  private getUrlImage(path: string):  Observable<string> {
    return this.storage.ref(path).getDownloadURL();
  }

  private getImageByName(name: string): Observable<Image> {
    return this.store.collection<Image>(
      `customers/${this.customer}/images`,
      ref => ref.where('name', '==', name).limit(1)
    )
    .valueChanges({ idField: 'id' })
    .pipe(map(img => img[0]));
  }
}


