import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserLogLevel, UserLogType } from '../models/user.model';
import { LoggerService } from './logger.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private logger: LoggerService,
    private route: Router
  ) {
  }

  handleError(error: any) {
    const url = this.route.url;
    const message = error?.message || '';

    this.logger.registerLog(UserLogType.Log, UserLogLevel.Error, 'error.service', { url, error: message });
  }
}
