import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { TUI_ICONS_PATH, iconsPathFactory, TuiRootModule, TuiNotificationsModule, TuiDialogModule } from '@taiga-ui/core';

import { environment } from '@maserati/env/environment';

import { RootComponent } from './components/root/root.component';
import { ImageService } from './services/image.service';
import { SchemaService } from './services/schema.service';
import { DataService } from './services/data.service';
import { SavingService } from './services/saving.service';
import { LoggerService } from './services/logger.service';
import { ConfigService } from './services/config.service';
import { PublishService } from './services/publish.service';
import { TenantService } from './services/tenant.service';
import { GlobalErrorHandler } from './services/error.service';


const routes = [
  {
    path: '',
    component: RootComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@maserati/modules/restricted/restricted.module').then(m => m.RestrictedModule)
      }
    ]
  }
];

const taiga = [
  TuiRootModule,
  TuiDialogModule,
  TuiNotificationsModule
];

const firebase = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireFunctionsModule,
  AngularFireStorageModule
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    ...taiga,
    ...firebase
  ],
  declarations: [
    RootComponent
  ],
  providers: [
    { provide: REGION, useValue: 'southamerica-east1' },
    {
      provide: TUI_ICONS_PATH,
      useValue: iconsPathFactory('assets/taiga-ui/icons/'),
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ImageService,
    SchemaService,
    DataService,
    SavingService,
    LoggerService,
    ConfigService,
    PublishService,
    TenantService
  ]
})
export class CoreModule {
}
