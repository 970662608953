import { DatabaseItem } from './database.model';

export interface User extends DatabaseItem{
  name: string;
  email: string;
  customer?: string[];
  token?: boolean;
  photo?: string;
  type: UserType;
  provider: string;
  enabled: boolean;
  claims?: boolean;
  logger: UserLogLevel;
}

export interface UserLog extends DatabaseItem{
  userAgent: string;
  type: UserLogType;
  log?: any;
}

export enum UserLogType {
  Login = 'login',
  Logout = 'logout',
  Log = 'log'
}

export enum UserLogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
  Essential = 10
}

export enum UserType {
  Hackerman = 'hackerman',
  Admin = 'admin',
  Editor = 'editor'
}

