import { Injectable, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { format } from 'date-fns';

import { LoggerService } from './logger.service';
import { User, UserLogLevel } from '../models/user.model';
import { StatusType } from '../models/publish.model';
import { ConfigLink } from '../models/config.model';
import { TenantService } from './tenant.service';
import { DataItem } from '../models/data.model';


@Injectable()
export class PublishService {

  user!: User;
  customer!: string;


  constructor(
    private logger: LoggerService,
    private functions: AngularFireFunctions,
    private store: AngularFirestore,
    private tenant: TenantService
  ){
    this.customer = this.tenant.customer;
  }


  async saveVersion(newVersion: string, versionName: string, schedule?: number): Promise<void> {
    try {
      await this.cancelScheduled();

      const listDataWithoutSchedule = {
        version: newVersion,
        user: this.user.name,
        status: schedule ? StatusType.Scheduled : StatusType.InProgress,
        updated: new Date(),
        name: versionName
      };
      const listData = schedule ? { ...listDataWithoutSchedule, schedule } : { ...listDataWithoutSchedule };

      const status = await this.getStatus().pipe(take(1)).toPromise() || { current: '', inprogress: false };

      await this.store.doc(`customers/${this.customer}/pages/status`).set(status);
      await this.store.doc(`customers/${this.customer}/pages/status/list/${listData.version}`).set(listData);
    } catch (error) {
      this.logger.log('{publish.service, saveVersion()}', error, UserLogLevel.Error);
    }
  }

  async rollbackVersion(version: string) {
    const customer = this.customer;
    await this.functions.httpsCallable('rollbackVersion')({ version, customer }).toPromise();
  }

  async cancelScheduled() {
    const scheduled = await this.getScheduledVersion();

    if (scheduled) {
      await this.store.doc(`customers/${this.customer}/pages/status/list/${scheduled.id}`).update({status: StatusType.Canceled});
    }
  }

  async getScheduledVersion(): Promise<DataItem> {
    return (await this.getVersionsList().pipe(take(1)).toPromise())
      .filter(item => item.status === StatusType.Scheduled)[0];
  }

  async goToUrl(version: string): Promise<void> {
    let url = '';

    try {
      const path = `customers/${this.customer}/config/link`;
      const link = await this.store.doc<ConfigLink>(path).valueChanges().pipe(take(1)).toPromise();
      if (link) {
        url = `https://${link.url}/?version=` + version;
      }
    } catch (error) {
      this.logger.log('{publish.service, goToUrl()}', error, UserLogLevel.Error);
    }

    window.open(url, '_blank');
  }

  async publishData(): Promise<boolean> {
    try {
      const customer = this.customer;
      const status = await this.functions.httpsCallable('publishData')({ customer }).toPromise();
      return status;
    } catch (error) {
      this.logger.log('{publish.service, publishData()}', error, UserLogLevel.Error);
    }

    return false;
  }

  getStatus(): Observable<{current: string; inprogress: boolean} | null> {
    return this.store.doc<{current: string; inprogress: boolean}>(`customers/${this.customer}/pages/status`)
      .valueChanges()
      .pipe(map(data => data ? data : null));
  }

  getVersionsList(): Observable<DataItem[]> {
    return this.store.collection<DataItem>(`customers/${this.customer}/pages/status/list`)
      .valueChanges({ idField: 'id' });
  }
}





